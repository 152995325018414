import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/all_products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/all_products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="content">
              <div className="gallery-page">
                <h1 className="gallery-page__title">ΠΡΟIOΝΤΑ PIZ BUIN®</h1>
                <div className="clearfix">
                  <div className="gallery-page__filter hidden">
                    <div className="arrow">
                      <br />
                    </div>
                    <form method id="fmFilter">
                      <select
                        name="filter"
                        className="gallery-page__filter__select"
                      >
                        <optgroup>
                          <option value="0">Filter by: All</option>
                          <option value="1,2,6,9,12,13" />
                          <option value="12,18,19" />
                          <option value="1,2,4" />
                          <option value="1" />
                          <option value="13" />
                        </optgroup>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="innerContent clearfix gallery-page__list">
                  <a
                    href="/gr/our-products/moisturising/#moisturising-sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          MOISTURISING SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/moisturising/#stick-labial"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LIPSTICK
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/moisturising/#ultra-light-hydrating-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/8bf310057998eb9035da232074f98217_f33.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          ULTRA LIGHT HYDRATING SUN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/hydro-infusion/#sun-gel-cream-face"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM FACE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/hydro-infusion/#sun-gel-cream"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-sun-gel-cream-50spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/sensitive/#face-cream"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-sensitive-face-cream-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Sensitive
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          FACE CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/sensitive/#skin-spray"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-sensitive-skin-spray-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Sensitive
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SKIN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/sensitive/#skin-lotion"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-sensitive-skin-lotion-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Sensitive
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SKIN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/tan_protect/#tan-accelerating-oil-spray"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/597e55fa85eadc497dda9761c9c29e48_f305.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN ACCELERATING OIL SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/tan_protect/#tan-intensifying-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/33e7e9d0e3e36224abe8382693902bac_f547.png" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING SUN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/instantglow/#skin-illuminating-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-instant-glow-skin-illuminating-sun-spray-30spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          Skin illuminating sun spray
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/instantglow/#skin-illuminating-sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/3bcb3030ed32a15c92fe23eb9abbbfa1_f542.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SKIN ILLUMINATING SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/aftersun/#instant-relief-mist-spray"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          INSTANT RELIEF MIST SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/gr/our-products/aftersun/#tan-intensifying-moisturisation-lotion"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING MOISTURISATION LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $(".heroImage > img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$(".heroImage").empty();\n})\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
